<template>
  <Breadcrumbs
    :items="generateBreadcrumbs()"
    class="mx-xs md:mx-md my-xs md:my-xs"
    data-test-id="breadcrumbs"
    data-fs="breadcrumbs"
  />
  <LazyDropShipTray />
  <LazyCategoryFiltersTray
    :is-filter-tray="true"
    :facets="facets"
    :total-found-items="categoryBCData.total"
    v-if="categoryBCData"
    :active-category-id="currentCategoryBrId"
  />

  <br-component component="main" v-if="isCatLanding">
    <template #default="{ component }">
      <br-component :component="component" />
    </template>
  </br-component>

  <Grid
    v-else
    tag="article"
    gap="3xl"
    class="bg-neutral-lighter"
    data-test-id="plpProductsContainer"
    data-fs="plpProductsContainer"
  >
    <GridItem col-span="4" :breakpoints="{}" class="!block sm:!hidden">
      <LazyCategoryDetails />
    </GridItem>

    <GridItem tag="aside" col-span="4" :breakpoints="{ sm: '3', md: '2', lg: '3', xl: '2' }">
      <LazyCategoryFilters
        :is-filter-tray="false"
        :facets="facets"
        :total-found-items="categoryBCData.total"
        v-if="categoryBCData"
        :active-category-id="currentCategoryBrId"
        class="hidden sm:block"
      />
    </GridItem>

    <GridItem tag="section" col-span="4" :breakpoints="{ sm: '5', md: '6', lg: '9', xl: '10' }">
      <LazyCategoryToolbar @sort-by="onSortBy" />
      <LazyCategoryGrid
        :prices="pricesRef"
        :items-per-page="itemsPerPage"
        :total-found-items="categoryBCData.total"
        v-if="categoryBCData"
        :is-search-page="false"
        :fetch-new-page="fetchNewPage"
        :initial-sort="sortTerm"
        :is-loading="loadingItems"
        :is-search="false"
      />
    </GridItem>
  </Grid>
</template>

<script setup lang="ts">
import { useCustomEvents } from "mkm-gtm";

const title = ref();
const meta = ref<{ name: string; hid: string; content: any }[]>([]);

useHead({
  title: title,
  meta: meta,
  bodyAttrs: {
    class: "bg-neutral-lighter",
  },
});

const isCatLanding = ref<boolean>(false);

const { sortTerm } = useSortBy();
const { data: categoryBCData, loadingItems, bigCommerceCategory } = useCategory();
const { user } = useUser();
const { selectedBranch } = useBranches();
const pricesRef = ref<Record<string, number>>({});

const {
  isCatLandingLayout,
  onSortBy,
  categoryName,
  itemsPerPage,
  facets,
  currentCategoryBrId,
  fetchNewPage,
  generateBreadcrumbs,
  generatePageTitle,
  fetchItemsOnFirstMounting,
  categoryBC,
  usePreloadLCPImage,
} = useCategoryPage();
const { pushCustomEvent, CustomEvents } = useCustomEvents();

const { initSearch } = useFilters();
const { getPrice } = usePrices();

const setPageSeo = () => {
  const defaultDescription = `Buy ${bigCommerceCategory?.value?.name} online, with free local delivery at mkm.com`;

  meta.value = [
    {
      name: "og:description",
      hid: "og:description",
      content: bigCommerceCategory?.value?.meta_description || defaultDescription,
    },
    {
      name: "description",
      hid: "description",
      content: bigCommerceCategory?.value?.meta_description || defaultDescription,
    },
  ];

  const defaultTitle = `${generatePageTitle()} | MKM Building Supplies `;
  title.value = bigCommerceCategory.value?.page_title || defaultTitle;
};

initSearch();

const getBasePrices = async (): Promise<Record<string, number>> => {
  return categoryBCData.value?.items?.map((item: any) => {
    const desiredKeys = ["price", "offer_price", "price_inc_vat", "offer_price_inc_vat", "unit", "offer_unit"];

    const result: { [key: string]: any } = {};

    desiredKeys.forEach((key) => {
      const attr = item.customAttrs.find((attribute: any) => attribute.name === key);
      if (attr) {
        result[key as string] = attr.values[0] as string;
      }
    });

    return {
      product_sku: item.itemId.id,
      branch_id: selectedBranch.value?.id,
      ...result,
    };
  });
};

categoryName.value = categoryBC?.name;

if (categoryBC?.id && categoryBC?.is_visible) {
  setPageSeo();
  currentCategoryBrId.value = categoryBC.id.toString();

  await fetchItemsOnFirstMounting(categoryBC?.id, 20);

  isCatLanding.value = isCatLandingLayout();
}

usePreloadLCPImage();

onMounted(() => {
  setTimeout(async () => {
    const userCategory = user.value?.account.business_type.split("|");
    const isTrade = userCategory?.[0] === "NTrade" ? "NON-TRADE" : userCategory?.[0] === "Trade" ? "TRADE" : null;
    await pushCustomEvent(CustomEvents.CATEGORY_PAGE_VIEW, {
      logged_in: user.value !== null || false,
      user_id: user?.value?.id ?? null,
      customer_category_1: user ? isTrade : "Guest",
      customer_category_2: userCategory?.[1] ?? null,
      customer_category_3: userCategory?.[2] ?? null,
      account_type: user.value?.account.type ?? null,
      account_owning_branch: user.value?.account.branch_id ?? null,
      selected_branch: selectedBranch.value?.name ?? null,
      page_type: "category",
    });
  }, 2000);
});

watch(
  [user, categoryBCData],
  async () => {
    await nextTick();

    if (user.value) {
      pricesRef.value = await getPrice(categoryBCData.value?.items?.map((item: any) => item?.itemId?.id)?.join(","));
    } else if (selectedBranch.value) {
      pricesRef.value = await getBasePrices();
    }
  },
  { immediate: true },
);
</script>
